import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext } from "react";

import { ProfileSecuritySection } from "../components/profile";
import { AuthContext } from "../contexts";

export const UserProfile = () => {
  const { state } = useContext(AuthContext);

  if (!state.user) {
    return null;
  }

  return (
    <Container sx={{ marginTop: 3 }} maxWidth="md">
      <Grid container spacing={2} alignContent="center" justifyContent="center">
        <Avatar sx={{ width: 56, height: 56 }}>
          <PersonIcon />
        </Avatar>
        <ProfileSecuritySection user={state.user} />
      </Grid>
    </Container>
  );
};
