import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ConfirmResetPasswordForm,
  FORGOT_PASSWORD_STEP,
  ForgotPasswordForm,
} from "../components/forgot-password";

export const ForgotPassword = () => {
  const [activeStep, setActiveStep] = useState(FORGOT_PASSWORD_STEP.Reset);
  const [userEmail, setUserEmail] = useState<string>("");
  const navigate = useNavigate();

  const completeReset = () => {
    navigate("/sign-in");
  };

  const switchStep = (step: number) => {
    switch (step) {
      default:
      case FORGOT_PASSWORD_STEP.Reset:
        return (
          <ForgotPasswordForm
            setUserEmail={setUserEmail}
            nextStep={() => setActiveStep(FORGOT_PASSWORD_STEP.ConfirmReset)}
          />
        );
      case FORGOT_PASSWORD_STEP.ConfirmReset:
        return (
          <ConfirmResetPasswordForm
            email={userEmail}
            nextStep={completeReset}
          />
        );
    }
  };

  return switchStep(activeStep);
};
