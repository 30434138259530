import { Account, AccountChart } from "../models";

import { client } from "./client";
import { accountChartSchema, accountSchema, accountsSchema } from "./schemas";

export abstract class AccountsApi {
  public static listAccounts(): Promise<Account[]> {
    return client
      .get("/accounts")
      .then((res) => accountsSchema.parse(res.data));
  }

  public static createAccount(data: {
    name: string;
    amount: number;
  }): Promise<Account> {
    return client
      .put("/accounts", data)
      .then((res) => accountSchema.parse(res.data));
  }

  public static getAccount(id: string): Promise<Account> {
    return client
      .get(`/accounts/${id}`)
      .then((res) => accountSchema.parse(res.data));
  }

  public static updateAccount(
    id: string,
    data: { name: string }
  ): Promise<Account> {
    return client
      .post(`/accounts/${id}`, data)
      .then((res) => accountSchema.parse(res.data));
  }

  public static deleteAccount(id: string): Promise<void> {
    return client.delete(`/accounts/${id}`);
  }

  public static getAccountChartDaily(
    id: string,
    params: { startDate: Date; endDate: Date }
  ): Promise<AccountChart> {
    return client
      .get(`/accounts/${id}/charts/daily`, { params })
      .then((res) => accountChartSchema.parse(res.data));
  }
}
