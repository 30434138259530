import { updatePassword } from "@aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Fragment, PropsWithoutRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { User } from "../../models";
const formSchema = z.object({
  password: z.string(),
  newPassword: z
    .string()
    .min(12, "tooShortPassword")
    .regex(/[a-z]/, "missingLowerCase")
    .regex(/[A-Z]/, "missingUpperCase")
    .regex(/[0-9]/, "missingNumber")
    .regex(/[[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/, "missingSpecial"),
});

type FormInputs = z.infer<typeof formSchema>;

export const ProfileSecuritySection = (
  props: PropsWithoutRef<{ user: User }>
) => {
  const { user } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: { password: "*********" },
  });
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [editSection, setEditSection] = useState(false);

  const handleEditOpen = () => {
    setValue("password", "");
    setEditSection(true);
  };

  const handleEditClose = () => {
    setValue("password", "**********"); // TODO: use default value from form
    setErrorCode(null);
    setEditSection(false);
  };

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    updatePassword({
      oldPassword: fields.password,
      newPassword: fields.newPassword,
    })
      .then(() => {
        reset();
        handleEditClose();
      })
      .catch((err) => {
        if (err instanceof Error && err.name === "NotAuthorizedException") {
          setErrorCode("invalidPassword");
          return;
        }
        if (err instanceof Error && err.name === "LimitExceededException") {
          setErrorCode("limitExceeded");
          return;
        }

        console.log(err);
        setErrorCode("internalError");
      });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            title={
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="overline">
                  {t("profile.texts.securityInfo")}
                </Typography>
                {!editSection ? (
                  <IconButton onClick={handleEditOpen}>
                    <EditIcon />
                  </IconButton>
                ) : null}
              </Box>
            }
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <TextField
                fullWidth
                label={t("profile.fields.email")}
                variant="standard"
                disabled={true}
                required={true}
                defaultValue={user.email}
              />
              <TextField
                fullWidth
                label={t("profile.fields.password")}
                variant="standard"
                type="password"
                autoComplete="current-password"
                error={Boolean(errors.password?.message)}
                helperText={
                  errors.password?.message
                    ? t(`security.errors.${errors.password?.message}`)
                    : null
                }
                required={true}
                disabled={!editSection}
                {...register("password")}
              />
              {editSection ? (
                <TextField
                  fullWidth
                  label={t("profile.fields.password")}
                  variant="standard"
                  type="password"
                  autoComplete="new-password"
                  error={Boolean(errors.password?.message)}
                  helperText={
                    errors.newPassword?.message
                      ? t(`security.errors.${errors.newPassword?.message}`)
                      : null
                  }
                  required={true}
                  disabled={!editSection}
                  {...register("newPassword")}
                />
              ) : null}
            </Grid>
            {errorCode ? (
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {t(`security.errors.${errorCode}`)}
              </Alert>
            ) : null}
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            {editSection ? (
              <Fragment>
                <Button onClick={handleEditClose}>
                  {t("profile.actions.cancel")}
                </Button>
                <Button type="submit">{t("profile.actions.save")}</Button>
              </Fragment>
            ) : null}
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};
