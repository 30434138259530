import { signIn } from "@aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  email: z.string(),
  password: z.string(),
});

type FormInputs = z.infer<typeof formSchema>;

export const SignInForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
  });
  const [errorCode, setErrorCode] = useState<string | undefined>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = ({ email, password }) => {
    signIn({ username: email, password })
      .then((res) => {
        if (res.isSignedIn) {
          return navigate("/");
        }
        throw new Error("Not signed in");
      })
      .catch((err) => {
        if (err.name === "NotAuthorizedException") {
          setErrorCode("loginFailed");
          return;
        }
        console.error(err);
        setErrorCode("internalError");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("security.texts.signIn")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label={t("security.fields.email")}
                autoComplete="email"
                {...register("email")}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label={t("security.fields.password")}
                type="password"
                autoComplete="new-password"
                {...register("password")}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="submit" fullWidth variant="contained">
                {t("security.actions.signIn")}
              </Button>
            </Grid>
            <Grid size={{ xs: 12 }} direction="column" width="100%">
              <Grid container justifyContent="right">
                <Link href="/forgot-password" variant="body2">
                  {t("security.texts.forgotPassword")}
                </Link>
              </Grid>
              <Grid container justifyContent="right">
                <Link href="/sign-up" variant="body2">
                  {t("security.texts.noAccount")}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {errorCode ? (
          <Alert severity="error">{t(`security.errors.${errorCode}`)}</Alert>
        ) : null}
      </Box>
    </Container>
  );
};
