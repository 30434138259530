import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { Fragment, PropsWithoutRef, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { parseCurrency } from "../../helpers";
import { dateToMonthShort } from "../../translations";
import { RecurrentService } from "../../services";

const formSchema = z.object({
  accountId: z.string().min(1),
  day: z.number().int().min(1).max(28),
  amount: z.string(),
  name: z.string().min(1),
  comment: z.string().nullish(),
  month1: z.boolean(),
  month2: z.boolean(),
  month3: z.boolean(),
  month4: z.boolean(),
  month5: z.boolean(),
  month6: z.boolean(),
  month7: z.boolean(),
  month8: z.boolean(),
  month9: z.boolean(),
  month10: z.boolean(),
  month11: z.boolean(),
  month12: z.boolean(),
  active: z.boolean(),
});

type FormInputs = z.infer<typeof formSchema> & {
  [key: `month${number}`]: boolean;
};

export const RecurrentAdd = (
  props: PropsWithoutRef<{
    accountId: string;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const recurrentService = new RecurrentService(accountContext);
  const [confExitOpen, setConfExitOpen] = useState(false);
  const { t } = useTranslation();
  const { accountId, open, handleClose: handleFormClose } = props;
  const { formState, handleSubmit, register, reset } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      accountId,
      day: 1,
      month1: true,
      month2: true,
      month3: true,
      month4: true,
      month5: true,
      month6: true,
      month7: true,
      month8: true,
      month9: true,
      month10: true,
      month11: true,
      month12: true,
      active: true,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    const amount = parseCurrency(fields.amount);
    recurrentService.createRecurrent({ ...fields, amount }).then(() => {
      handleClose({ showConfirmation: false });
    });
  };

  const handleClose = (props: { showConfirmation: boolean }) => {
    if (props.showConfirmation && formState.isDirty) {
      setConfExitOpen(true);
      return;
    }

    reset();
    setConfExitOpen(false);
    handleFormClose();
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose.bind(null, { showConfirmation: true })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("recurrents.texts.addRecurrent")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label={t("recurrents.fields.name")}
                  required={true}
                  {...register("name")}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label={t("recurrents.fields.comment")}
                  {...register("comment")}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <NumericFormat
                  fullWidth
                  label={t("recurrents.fields.amount")}
                  suffix=" €"
                  thousandSeparator=" "
                  decimalSeparator=","
                  customInput={TextField}
                  required={true}
                  inputProps={{ ...register("amount") }}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <Select
                  fullWidth
                  label={t("recurrents.fields.day")}
                  defaultValue={1}
                  {...register("day")}
                >
                  {Array(28)
                    .fill(null)
                    .map((_, i) => (
                      <MenuItem key={`recurrent_edit_day_${i}`} value={i + 1}>
                        {t(`recurrents.fields.daySelect`, { day: i + 1 })}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid container size={{ xs: 12 }} sx={{ mt: 1 }}>
                {Array(12)
                  .fill(null)
                  .map((_, i) => (
                    <Grid key={`recurrent_edit_month_${i}`} size={{ xs: 4 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={true}
                            {...register(`month${i + 1}`)}
                          />
                        }
                        label={dateToMonthShort(
                          DateTime.fromObject({ month: i + 1 }).toJSDate()
                        )}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose.bind(null, { showConfirmation: true })}
            >
              {t("recurrents.actions.cancel")}
            </Button>
            <Button type="submit">{t("recurrents.actions.add")}</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={confExitOpen}>
        <DialogTitle>
          {t("recurrents.texts.exitConfirmation.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("recurrents.texts.exitConfirmation.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfExitOpen(false)}>
            {t("recurrents.actions.cancel")}
          </Button>
          <Button
            onClick={handleClose.bind(null, { showConfirmation: false })}
            color="error"
          >
            {t("recurrents.actions.leave")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
