import { Card, Tab, Tabs } from "@mui/material";
import { Fragment, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

import { DailyChart } from "../charts";
import { RecurrentsList } from "../recurrent";
import { StatementsList } from "../statements";

const CustomTabPanel = (
  props: PropsWithChildren<{
    index: number;
    value: number;
  }>
) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const RightSideTabs = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  return (
    <Fragment>
      <Card sx={{ marginBottom: 2 }}>
        <Tabs
          value={tabValue}
          onChange={(_, value) => setTabValue(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("rightSideTabs.statements")} {...getTabProps(0)} />
          <Tab label={t("rightSideTabs.recurrents")} {...getTabProps(1)} />
          <Tab label={t("rightSideTabs.charts")} {...getTabProps(2)} />
        </Tabs>
      </Card>
      <Card>
        <CustomTabPanel value={tabValue} index={0}>
          <StatementsList />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <RecurrentsList />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <DailyChart />
        </CustomTabPanel>
      </Card>
    </Fragment>
  );
};
