import { AccountsApi } from "../apis";
import { IAccountContext } from "../contexts";

export class AccountService {
  constructor(private readonly _accountContext: IAccountContext) {}

  public createAccount(data: { name: string; amount: number }): Promise<void> {
    return AccountsApi.createAccount(data)
      .then((account) => {
        this._accountContext.dispatch({ type: "addAccount", payload: account });
      })
      .catch((err) => console.error(err));
  }

  public updateAccount(
    accountId: string,
    data: { name: string }
  ): Promise<void> {
    return AccountsApi.updateAccount(accountId, data)
      .then((account) => {
        this._accountContext.dispatch({ type: "setAccount", payload: account });
      })
      .catch((err) => console.error(err));
  }

  public deleteAccount(accountId: string): Promise<void> {
    return AccountsApi.deleteAccount(accountId)
      .then(() => {
        this._accountContext.dispatch({
          type: "deleteAccount",
          payload: accountId,
        });
      })
      .catch((err) => console.error(err));
  }

  public refreshAccount(): Promise<void> {
    const { accountId } = this._accountContext.state;
    if (!accountId) {
      return Promise.resolve();
    }

    return AccountsApi.getAccount(accountId)
      .then((account) => {
        this._accountContext.dispatch({ type: "setAccount", payload: account });
      })
      .catch((err) => console.error(err));
  }

  public listAccounts(): Promise<void> {
    return AccountsApi.listAccounts()
      .then((accounts) => {
        this._accountContext.dispatch({
          type: "setAccounts",
          payload: accounts,
        });
      })
      .catch((err) => console.error(err));
  }

  public getAccountChartDaily(startDate: Date, endDate: Date): Promise<void> {
    const { accountId } = this._accountContext.state;
    if (!accountId) {
      return Promise.resolve();
    }

    return AccountsApi.getAccountChartDaily(accountId, { startDate, endDate })
      .then((accountChart) => {
        this._accountContext.dispatch({
          type: "setAccountChart",
          payload: accountChart,
        });
      })
      .catch((err) => console.error(err));
  }

  public switchAccount(accountId: string): void {
    this._accountContext.dispatch({
      type: "setAccountId",
      payload: accountId,
    });
  }
}
