import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ConfirmSignUpForm,
  SIGN_UP_STEP,
  SignUpForm,
} from "../components/sign-up";

export const SignUp = () => {
  const [activeStep, setActiveStep] = useState(SIGN_UP_STEP.SignUp);
  const [userEmail, setUserEmail] = useState<string>("");
  const navigate = useNavigate();

  const completeSignUp = () => {
    navigate("/sign-in");
  };

  const switchStep = (step: number) => {
    switch (step) {
      default:
      case SIGN_UP_STEP.SignUp:
        return (
          <SignUpForm
            nextStep={() => setActiveStep(SIGN_UP_STEP.ConfirmSignUp)}
            setUserEmail={setUserEmail}
            completeSignUp={completeSignUp}
          />
        );
      case SIGN_UP_STEP.ConfirmSignUp:
        return (
          <ConfirmSignUpForm email={userEmail} nextStep={completeSignUp} />
        );
    }
  };

  return switchStep(activeStep);
};
