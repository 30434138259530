import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "./components/layout";
import { AuthProvider, AccountProvider } from "./contexts";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { SignIn } from "./pages/SignIn";
import { SignUp } from "./pages/SignUp";
import { UserProfile } from "./pages/UserProfile";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AccountProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="profile" element={<UserProfile />} />
            </Route>
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Routes>
        </AccountProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
