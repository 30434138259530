import { TransfersApi } from "../apis";
import { IAccountContext } from "../contexts";
import { Transfer } from "../models";
import { AccountService } from "./account.service";
import { StatementService } from "./statement.service";

export class TransferService {
  private readonly _accountService: AccountService;

  private readonly _statementService: StatementService;

  constructor(private readonly _accountContexte: IAccountContext) {
    this._accountService = new AccountService(_accountContexte);
    this._statementService = new StatementService(_accountContexte);
  }

  public createTransfer(data: {
    accountId: string;
    name: string;
    comment?: string;
    date: Date;
    done: boolean;
    amount: number;
  }): Promise<void> {
    return TransfersApi.createTransfer(data)
      .then((transfer) => {
        this._accountContexte.dispatch({
          type: "addTransfer",
          payload: transfer,
        });
        Promise.all([
          this._accountService.refreshAccount(),
          this._statementService.refreshStatementForTransfer(transfer),
        ]);
      })
      .catch((err) => console.error(err));
  }

  public updateTransfer(
    transferId: string,
    data: {
      name: string;
      comment?: string;
      date: Date;
      done: boolean;
      amount: number;
    },
    oldDate: Date
  ): Promise<void> {
    return TransfersApi.updateTransfer(transferId, data)
      .then((transfer) => {
        this._accountContexte.dispatch({
          type: "replaceTransfer",
          payload: transfer,
        });
        Promise.all([
          this._accountService.refreshAccount(),
          this._statementService.refreshStatementForTransfer(transfer, oldDate),
        ]);
      })
      .catch((err) => console.error(err));
  }

  public updateTransferStatus(
    transferId: string,
    status: boolean
  ): Promise<void> {
    return TransfersApi.updateTransferStatus(transferId, status)
      .then((transfer) => {
        this._accountContexte.dispatch({
          type: "replaceTransfer",
          payload: transfer,
        });
        Promise.all([
          this._accountService.refreshAccount(),
          this._statementService.refreshStatementForTransfer(transfer),
        ]);
      })
      .catch((err) => console.error(err));
  }

  public deleteTransfer(transfer: Transfer): Promise<void> {
    return TransfersApi.deleteTransfer(transfer.id)
      .then(() => {
        this._accountContexte.dispatch({
          type: "deleteTransfer",
          payload: transfer.id,
        });
        Promise.all([
          this._accountService.refreshAccount(),
          this._statementService.refreshStatementForTransfer(transfer),
        ]);
      })
      .catch((err) => console.error(err));
  }

  public listTransfers(
    accountId: string,
    pagination: {
      limit: number;
      skip: number;
      order: Record<string, "ASC" | "DESC">;
    }
  ): Promise<void> {
    return TransfersApi.listTransfers(accountId, pagination)
      .then(({ transfers, total }) => {
        this._accountContexte.dispatch({
          type: "addTransfers",
          payload: transfers,
        });

        this._accountContexte.dispatch({
          type: "setTransfersCount",
          payload: total,
        });
      })
      .catch((err) => console.error(err));
  }

  public emptyTransfers(): void {
    this._accountContexte.dispatch({ type: "setTransfers", payload: [] });
  }
}
