import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Fragment, PropsWithoutRef, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { Statement } from "../../models";
import { StatementService } from "../../services";

const formSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
});

type FormInputs = z.infer<typeof formSchema>;

export const StatementEdit = (
  props: PropsWithoutRef<{
    statement: Statement;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const statementService = new StatementService(accountContext);
  const [confDeleteOpen, setConfDeleteOpen] = useState(false);
  const [confExitOpen, setConfExitOpen] = useState(false);
  const { t } = useTranslation();
  const { statement, open, handleClose: handleFormClose } = props;
  const { formState, handleSubmit, register, reset, setValue } =
    useForm<FormInputs>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        startDate: statement.startDate,
        endDate: statement.endDate,
      },
    });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    statementService
      .updateStatement(statement.id, fields)
      .then(() => handleClose({ showConfirmation: false }));
  };

  const handleConfDelete = () => {
    statementService
      .deleteStatement(statement.id)
      .then(() => handleClose({ showConfirmation: false }));
  };

  const handleClose = (props: { showConfirmation: boolean }) => {
    if (props.showConfirmation && formState.isDirty) {
      setConfExitOpen(true);
      return;
    }

    reset();
    setConfExitOpen(false);
    setConfDeleteOpen(false);
    handleFormClose();
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose.bind(null, { showConfirmation: true })}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{t("statements.texts.editStatement")}</DialogTitle>
            <DialogContent>
              <Grid spacing={2}>
                <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                  <DatePicker
                    defaultValue={DateTime.fromJSDate(statement.startDate)}
                    required={true}
                    {...register("startDate")}
                    onChange={(v) => v && setValue("startDate", v.toJSDate())}
                  />
                </Grid>
                <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                  <DatePicker
                    defaultValue={DateTime.fromJSDate(statement.endDate)}
                    required={true}
                    {...register("endDate")}
                    onChange={(v) => v && setValue("endDate", v.toJSDate())}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose.bind(null, { showConfirmation: true })}
              >
                {t("statements.actions.cancel")}
              </Button>
              <Button onClick={() => setConfDeleteOpen(true)} color="error">
                {t("statements.actions.delete")}
              </Button>
              <Button type="submit">{t("statements.actions.save")}</Button>
            </DialogActions>
          </form>
        </LocalizationProvider>
      </Dialog>

      <Dialog open={confDeleteOpen}>
        <DialogTitle>
          {t("statements.texts.deleteConfirmation.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("statements.texts.deleteConfirmation.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfDeleteOpen(false)}>
            {t("statements.actions.cancel")}
          </Button>
          <Button onClick={handleConfDelete} color="error">
            {t("statements.actions.delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confExitOpen}>
        <DialogTitle>{t("transfers.texts.exitConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("statements.texts.exitConfirmation.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfExitOpen(false)}>
            {t("statements.actions.cancel")}
          </Button>
          <Button
            onClick={handleClose.bind(null, { showConfirmation: false })}
            color="error"
          >
            {t("statements.actions.leave")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
