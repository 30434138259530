import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DateTime, Interval } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { dateToDateString, numberToCurrencyString } from "../../translations";
import { AccountService } from "../../services";
import { AccountContext } from "../../contexts";

interface AccountChartItemFormated {
  date: Date;
  positive: number | undefined;
  negative: number | undefined;
  total: number;
}

enum INTERVAL_KEY {
  Last31Days = 0,
  Last3Months = 1,
  LastAndNextMonths = 2,
}

const INTERVAL_DATES = {
  [INTERVAL_KEY.Last31Days]: Interval.fromDateTimes(
    DateTime.now().minus({ days: 30 }),
    DateTime.now()
  ) as Interval<true>,
  [INTERVAL_KEY.Last3Months]: Interval.fromDateTimes(
    DateTime.now().minus({ months: 3 }),
    DateTime.now()
  ) as Interval<true>,
  [INTERVAL_KEY.LastAndNextMonths]: Interval.fromDateTimes(
    DateTime.now().minus({ month: 1 }),
    DateTime.now().plus({ month: 1 })
  ) as Interval<true>,
};

const TooltipContent = (props: TooltipProps<number, string>) => {
  const positive =
    props.payload?.find((el) => el.dataKey === "positive")?.value || 0;
  const negative =
    props.payload?.find((el) => el.dataKey === "negative")?.value || 0;
  const total = props.payload?.find((el) => el.dataKey === "total")?.value || 0;
  const variation = positive - negative;

  return (
    <Card>
      <CardHeader title={dateToDateString(props.label)} />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            align="right"
            color={variation >= 0 ? "success" : "error"}
          >
            {numberToCurrencyString(total - variation)}
            {variation >= 0 ? " + " : " - "}
            {numberToCurrencyString(Math.abs(variation))}
          </Typography>
          <DoubleArrowIcon color="action" />
          <Typography
            variant="body1"
            align="right"
            color={total >= 0 ? "success" : "error"}
          >
            {numberToCurrencyString(total)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const DailyChart = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<AccountChartItemFormated[]>([]);
  const [intervalKey, setIntervalKey] = useState<keyof typeof INTERVAL_DATES>(
    INTERVAL_KEY.Last31Days
  );
  const accountContext = useContext(AccountContext);
  const { accountId, accountChart } = accountContext.state;
  const accountService = new AccountService(accountContext);

  useEffect(() => {
    accountService.getAccountChartDaily(
      INTERVAL_DATES[intervalKey].start.toJSDate(),
      INTERVAL_DATES[intervalKey].end.toJSDate()
    );
  }, [accountId, intervalKey]);

  useEffect(() => {
    const computeOffset = (amount: number, total: number) => {
      if (total >= 0 && amount >= 0) {
        return total - amount;
      }
      if (total >= 0 && amount < 0) {
        return total;
      }
      if (total < 0 && amount < 0) {
        return total;
      }
      if (total < 0 && amount >= 0) {
        return total - amount;
      }

      throw new Error(`Case not handled total: ${total}, amount: ${amount}`);
    };

    setData(
      accountChart.map((d) => ({
        date: d.date,
        total: d.total,
        offset: computeOffset(d.amount, d.total),
        positive: d.amount > 0 ? d.amount : undefined,
        negative: d.amount < 0 ? Math.abs(d.amount) : undefined,
      }))
    );
  }, [accountChart]);

  const getTabProps = (index: string) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", height: "400px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={dateToDateString} />
            <YAxis tickFormatter={numberToCurrencyString} />
            <Tooltip
              labelFormatter={dateToDateString}
              content={TooltipContent}
            />
            <Bar dataKey="offset" stackId="stack1" fill="#00000000" />
            <Bar dataKey="positive" stackId="stack1" fill="#29a02f" />
            <Bar dataKey="negative" stackId="stack1" fill="#d82d2d" />
            <Line dataKey="total" strokeWidth={0} dot={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Box>
        <Tabs
          value={intervalKey}
          onChange={(_, value) => setIntervalKey(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(INTERVAL_DATES).map((key) => (
            <Tab
              key={`daily_chart_interval_${key}`}
              label={t(`charts.fields.interval.${key}`)}
              {...getTabProps(key)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};
