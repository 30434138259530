import { StatementApi } from "../apis";
import { IAccountContext } from "../contexts";
import { Transfer } from "../models";

export class StatementService {
  constructor(private readonly _accountContexte: IAccountContext) {}

  public createStatement(data: {
    accountId: string;
    startDate: Date;
    endDate: Date;
    createRecs: boolean;
  }): Promise<void> {
    return StatementApi.createStatement(data)
      .then((statement) => {
        this._accountContexte.dispatch({
          type: "addStatement",
          payload: statement,
        });
      })
      .catch((err) => console.error(err));
  }

  public updateStatement(
    statementId: string,
    data: { startDate: Date; endDate: Date }
  ): Promise<void> {
    return StatementApi.updateStatement(statementId, data)
      .then((statement) => {
        this._accountContexte.dispatch({
          type: "replaceStatement",
          payload: statement,
        });
      })
      .catch((err) => console.error(err));
  }

  public deleteStatement(statementId: string): Promise<void> {
    return StatementApi.deleteStatement(statementId)
      .then(() => {
        this._accountContexte.dispatch({
          type: "deleteStatement",
          payload: statementId,
        });
      })
      .catch((err) => console.error(err));
  }

  public listStatements(
    accountId: string,
    pagination: {
      limit: number;
      skip: number;
      order: Record<string, "ASC" | "DESC">;
    }
  ): Promise<void> {
    return StatementApi.listStatements(accountId, pagination)
      .then((res) => {
        this._accountContexte.dispatch({
          type: "setStatements",
          payload: res.data,
        });
        this._accountContexte.dispatch({
          type: "setStatementsCount",
          payload: res.total,
        });
      })
      .catch((err) => console.error(err));
  }

  public emptyStatements(): void {
    this._accountContexte.dispatch({ type: "setStatements", payload: [] });
    this._accountContexte.dispatch({ type: "setStatementsCount", payload: 0 });
  }

  public async refreshStatementForTransfer(
    transfer: Transfer,
    oldDate?: Date
  ): Promise<void> {
    const newStatement = this._accountContexte.state.statements.find(
      (s) => transfer.date >= s.startDate && transfer.date <= s.endDate
    );
    if (newStatement) {
      await StatementApi.getStatement(newStatement.id)
        .then((statement) => {
          this._accountContexte.dispatch({
            type: "replaceStatement",
            payload: statement,
          });
        })
        .catch((err) => console.error(err));
    }

    if (oldDate) {
      const oldStatement = this._accountContexte.state.statements.find(
        (s) => oldDate >= s.startDate && oldDate <= s.endDate
      );
      if (oldStatement) {
        await StatementApi.getStatement(oldStatement.id)
          .then((statement) => {
            this._accountContexte.dispatch({
              type: "replaceStatement",
              payload: statement,
            });
          })
          .catch((err) => console.error(err));
      }
    }
  }
}
