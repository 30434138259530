import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Fragment, PropsWithoutRef, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { Account } from "../../models";
import { AccountService } from "../../services";

const formSchema = z.object({
  name: z.string(),
});

type FormInputs = z.infer<typeof formSchema>;

export const AccountEdit = (
  props: PropsWithoutRef<{
    account: Account;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const accountService = new AccountService(accountContext);
  const [confDeleteOpen, setConfDeleteOpen] = useState(false);
  const [confExitOpen, setConfExitOpen] = useState(false);
  const { t } = useTranslation();
  const { account, open, handleClose: handleFormClose } = props;
  const { formState, handleSubmit, register, reset } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: account.name,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    accountService
      .updateAccount(account.id, { name: fields.name })
      .then(() => handleClose({ showConfirmation: false }));
  };

  const handleConfDelete = () => {
    accountService
      .deleteAccount(account.id)
      .then(() => handleClose.bind(null, { showConfirmation: false }));
  };

  const handleClose = (props: { showConfirmation: boolean }) => {
    if (props.showConfirmation && formState.isDirty) {
      setConfExitOpen(true);
      return;
    }

    reset();
    setConfExitOpen(false);
    setConfDeleteOpen(false);
    handleFormClose();
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose.bind(null, { showConfirmation: true })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("accounts.texts.editAccount")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  defaultValue={account.name}
                  label={t("accounts.fields.name")}
                  required={true}
                  {...register("name")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose.bind(null, { showConfirmation: true })}
            >
              {t("accounts.actions.cancel")}
            </Button>
            <Button onClick={() => setConfDeleteOpen(true)} color="error">
              {t("accounts.actions.delete")}
            </Button>
            <Button type="submit">{t("accounts.actions.save")}</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={confDeleteOpen}>
        <DialogTitle>
          {t("accounts.texts.deleteConfirmation.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("accounts.texts.deleteConfirmation.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfDeleteOpen(false)}>
            {t("accounts.actions.cancel")}
          </Button>
          <Button onClick={handleConfDelete} color="error">
            {t("accounts.actions.delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confExitOpen}>
        <DialogTitle>{t("accounts.texts.exitConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("accounts.texts.exitConfirmation.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfExitOpen(false)}>
            {t("accounts.actions.cancel")}
          </Button>
          <Button
            onClick={handleClose.bind(null, { showConfirmation: false })}
            color="error"
          >
            {t("accounts.actions.leave")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
